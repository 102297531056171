import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { identity } from 'fp-ts/function'
import * as z from 'zod'
import { hasValue, reorderArray } from '@digital-magic/ts-common-utils'
import {
  ImageVisibility,
  useCreateHousePlanImage,
  useDeleteHousePlanImage,
  useGetHousePlanImages,
  useUpdateHousePlanImageVisibility,
  useUpdateHousePlanImagesOrder
} from '@api/endpoints/buildings/houses'
import { getSmallThumbnailId, useFileUpload, useFilesDownload } from '@api/endpoints/files'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box, CircularProgress, SelectChangeEvent } from '@mui/material'
import { DropResult } from '@hello-pangea/dnd'
import { Button } from '@controls/Button'
import { DraggableImageList } from '@controls/DraggableImageList'
import { Form, FormFileInput, useFormTyped } from '@controls/Form'
import { SelectField } from '@controls/SelectField'
import { MenuItemEntry } from '@controls/types'
import { buildEnumOptions } from '@controls/utils'
import { useHouseFormContext } from '../HouseFormContext'
import { HousePlanImagesStyled } from './HousePlanImages.styles'

const HousePlanImagesFormValues = z.object({
  images: z.instanceof(FileList)
})
type HousePlanImagesFormValues = z.infer<typeof HousePlanImagesFormValues>

export const HousePlanImages: React.FC = () => {
  const { t } = useTranslation()
  const { house } = useHouseFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const uploadFile = useFileUpload({ onError: defaultErrorHandler })
  const getHousePlanImages = useGetHousePlanImages(house.id, { onError: defaultErrorHandler })
  const createHousePlanImage = useCreateHousePlanImage({ onError: defaultErrorHandler })
  const updateImageVisibility = useUpdateHousePlanImageVisibility({ onError: defaultErrorHandler })
  const deleteHousePlanImage = useDeleteHousePlanImage({ onError: defaultErrorHandler })
  const updateHousePlanImagesOrder = useUpdateHousePlanImagesOrder({ onError: defaultErrorHandler })

  const images = React.useMemo(
    () => getHousePlanImages.data?.concat()?.sort((a, b) => a.orderNumber - b.orderNumber) ?? [],
    [getHousePlanImages.data]
  )
  const downloadFiles = useFilesDownload(images.map(getSmallThumbnailId), { onError: defaultErrorHandler })

  const onSubmit = async (values: HousePlanImagesFormValues): Promise<void> => {
    // eslint-disable-next-line functional/no-loop-statements
    for (const image of values.images) {
      const uploadResp = await uploadFile.mutateAsync(image)
      await createHousePlanImage.mutateAsync({ houseId: house.id, fileId: uploadResp.id, visibility: 'Any' })
    }
  }

  const form = useFormTyped({
    resolver: HousePlanImagesFormValues,
    onSubmit
  })

  const imageUrls: ReadonlyArray<string> = React.useMemo(
    () => downloadFiles.data?.map((img) => URL.createObjectURL(img)) ?? [],
    [downloadFiles.data]
  )

  const visibilityOptions: ReadonlyArray<MenuItemEntry<ImageVisibility>> = React.useMemo(
    // TODO: Add translations
    () => buildEnumOptions(ImageVisibility, identity),
    []
  )

  const isFormDisabled = false //useMemo(() => !isEditableEntity(house.allowedActions), [house])
  //const isDeleteDisabled = useMemo(() => !isDeletableEntity(house.allowedActions), [house])

  const onDeleteImage =
    (index: number) =>
    (confirmResult: boolean): void => {
      const fileId = images[index].id
      if (hasValue(fileId) && confirmResult) {
        deleteHousePlanImage.mutate({ fileId, houseId: house.id })
      }
    }

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(images, result.source.index, result.destination.index).map((v, i) => ({
        ...v,
        orderNumber: i
      }))
      updateHousePlanImagesOrder.mutate({
        houseId: house.id,
        items: reordered.map((v) => ({
          item: v.id,
          index: v.orderNumber
        }))
      })
    }
  }

  const onImageVisibilityChange =
    (index: number) =>
    (e: SelectChangeEvent<unknown>): void => {
      const fileId = images[index].id

      if (hasValue(fileId)) {
        updateImageVisibility.mutate({ visibility: e.target.value as ImageVisibility, fileId, houseId: house.id })
      }
    }

  const isLoadingImages =
    getHousePlanImages.isFetching ||
    downloadFiles.isFetching ||
    createHousePlanImage.isLoading ||
    updateImageVisibility.isLoading ||
    deleteHousePlanImage.isLoading ||
    updateHousePlanImagesOrder.isLoading

  return (
    <Form f={form}>
      <HousePlanImagesStyled>
        {isLoadingImages ? (
          <CircularProgress />
        ) : (
          <DraggableImageList
            imageUrls={imageUrls}
            disabled={isFormDisabled}
            onDragEnd={onDragEnd}
            onDeleteImage={onDeleteImage}
            renderImage={(i, url) => (
              <>
                <Box height={210}>
                  <img
                    src={url}
                    alt={`image-${i}`}
                    style={{ /*width: '100%', maxHeight: '94%'*/ maxWidth: '200px', maxHeight: '200px' }}
                  />
                </Box>
                <SelectField
                  label={t('pages.admin.house.plan_images.form.field.visibility')}
                  options={visibilityOptions}
                  MenuProps={{ disableScrollLock: true }}
                  value={images[i].visibility}
                  onChange={onImageVisibilityChange(i)}
                />
              </>
            )}
          />
        )}
        <Box mt={2} mb={2} borderTop={1} color="#E6E6E6" />
        <Box>
          {t('pages.admin.house.plan_images.upload.title') + ': '}
          <FormFileInput disabled={isFormDisabled} multiple accept="image/*" name={form.names.images} />
        </Box>
        <div>
          <Button disabled={isFormDisabled} type="submit">
            {t('pages.admin.house.plan_images.buttons.add')}
          </Button>
        </div>
      </HousePlanImagesStyled>
    </Form>
  )
}
